import React, { useRef } from 'react'
import emailjs from 'emailjs-com'

const ContactUs = () => {

  const form = useRef();
  const sendEmail = (e)=>{
    e.preventDefault();

    emailjs.sendForm(
      "service_y5u2mva",
      "template_rgqwqan",
      form.current,
      "U2tlVxO3RWGop34lo"
    ).then(
      (result) => {
        form.current.reset();
        alert('Your message has been sent. Thank you for contacting.')
      },
      (error) => {
        console.log(error.status, error.text);
      }
    );
  };

  return (
    <>
    {/* <Navbar/> */}
    <div className="container my-4 contact">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title text-center">Contact Us</h2>
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input type="text" className="form-control" id="name" name="name" placeholder="Enter your name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input type="email" className="form-control" id="email" name="to_name" placeholder="Enter your email" required />
                  <small id="emailHelp"className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="query_message">Query:</label>
                  <textarea className="form-control" id="query_message" rows="5" name="message" placeholder="Enter your query" required></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">Send Email</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default  ContactUs;
