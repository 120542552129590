import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './CreateProfile.css'
import { StudentService } from '../../../services/student-services';
import Select from 'react-select';
import photo from '../../../img/photo.jpg'
import { useAuth } from '../../common/AuthContext';

const StudentCreateProfile = () => {
  // const [email, setEmail] = useState('');
  const {login} = useAuth();
  let navigate = useNavigate();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [licenseKey, setLicenseKey] = useState(''); 
  const [phone, setPhone] = useState('');
  // const [resume, setResume] = useState('');
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [github, setGithub] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [website, setWebsite] = useState('');
  const [headline,setHeadline] =  useState('');
  const [achievement,setAchievement] =  useState('');
  const [errorRequired,setErrorRequired] = useState(null);
  const [errorRegister,setErrorRegister] = useState(null);
  const [photo, setPhoto] = useState(null);
  // const [password, setPassword] = useState('');
  
  const location = useLocation();
  const interestsOptions = ['Software Development', 'E-commerce', 'HealtTech', 'FinTech', 'Gaming', 'Ed Tech', 'IOT'];
  const skillsOptions = ['JavaScript', 'Python', 'Java', 'C++', 'Ruby', 'Swift','Web Development', 'Data Science', 'Mobile App Development', 'UI/UX', 'Artificial Intelligence', 'Machine Learning', 'Cyber Security'];
  const password = location.state?.password || ''
  const email = location.state?.email || '';


  const handleSubmit = async () => {
    const skills_array = skills.map(item => item.value);
    const interests_array = interests.map(item => item.value);
    if (firstname && lastname && email && password && licenseKey) {
      setErrorRequired(null)
      setErrorRegister(null)
       const param = { 
          email, 
          firstname, 
          lastname,
          licenseKey,
          phone,
          // resume,
          // photo,
          skills: skills_array, 
          interests : interests_array,
          website,
          github,
          linkedin,
          password,
          headline,
          achievement,
          }
    const res = await StudentService.createStudent(param)
    if (res.data.status === 200) {
        const token = res.data.authtoken
        login(token,"student")
        navigate("/student/blogs");
    } else {
      setErrorRegister('Register failed due to ' + res.data.error_message);
    }
    } else {
      setErrorRequired('Please fill all required information.')
    }  
  };

  const selectOptions = (array) => {
    return array.map(option => ({ value: option, label: option }));
  };

  const handleInterestsChange = (selectedOptions) => {
    setInterests(selectedOptions);
  };

 const handleSkillsChange = (selectedOptions) => {
    setSkills(selectedOptions);
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='create-profile-page'> 
      <form> 
      <div className='create-profile-form'>
      <div className='font-size-large-title text-color title'>Student Registration</div>  
      <div className='personalInfo-section'>
        {/* <div className='flex-column gap-1 justify-center align-center' >
          <div className='profile-image'>
            {photo ? (
              <img src={photo} alt='profile' />
            ) : (
              <p>No photo uploaded</p>
            )}
          </div>
          <div>
            <label htmlFor='photo-upload' className='photo-upload-label'>
              Change photo
            </label>
            <input
              id='photo-upload'
              type='file'
              accept='image/*'
              onChange={handlePhotoChange}
              style={{ display: 'none' }}
            />
          </div>
        </div> */}
       
        <div className='flex-column gap-1' >
          <div className='font-size-large text-color'>Personal Information</div>
          <div className='personal-input input-section'>
            <div>  
              <label>First Name *</label>
              <input type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
            <div>
              <label>Last Name *</label>
              <input type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div>
              <label>License Key *</label>
              <input type="text" value={licenseKey} onChange={(e) => setLicenseKey(e.target.value)} />
            </div>
            <div>
              <label>Email</label>
              <input type="text" value={email} disabled/>
            </div>
            <div>
              <label>Phone</label>
              <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
          </div>
        </div>
        <div  className='flex-column gap-1' >
          <div className='font-size-large text-color'>Links</div>
          <div className='link-input input-section'>
            <div>
              <label>GitHub:</label>
              <input type="text" value={github} onChange={(e) => setGithub(e.target.value)} />
            </div>
            <div>
              <label>LinkedIn: </label>
              <input type="text" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} /> 
            </div>  
            <div>
              <label>Website:</label>
              <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
            </div>  
          </div>
        </div>
      </div>

      <div className='professional-section'>
        <div>
            <div  className='flex-column gap-1' >
            <div className='font-size-large text-color'>General</div>
            <div className='input-section general-input'>
              {/* <div className='upload-resume'>
                <label>Your resume</label>
                <input type="file" onChange={(e)=>setResume(e.target.files[0])}/>
              </div> */}
              <div>
                <label>Headlline </label>
                <textarea value={headline} onChange={(e) => setHeadline(e.target.value)} /> 
              </div>  
              <div>
                <label>Achievement</label>
                <textarea className='achievement-input' value={achievement} onChange={(e) => setAchievement(e.target.value)} />
              </div>  
            </div>
          </div>

        </div>
        <div>
          <div className='flex-column gap-1' >
            <div className='font-size-large text-color'>Skills</div>
            <div className='multi-select'>
              <Select
              isMulti
              name="skills"
              options={selectOptions(skillsOptions)}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSkillsChange}
              value={skills}
            />
            </div>      
        </div>
        <div  className='flex-column gap-1' >
          <div className='font-size-large text-color'>Interests</div>
          <div className='multi-select'>
            <Select
            isMulti
            name="interest"
            options={selectOptions(interestsOptions)}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleInterestsChange}
            value={interests}
          />
          </div>
        </div>
    </div>      
      </div>  
            <div className='button button-style1 submit-button' onClick={handleSubmit}>Submit</div>
            {errorRequired && <div>{errorRequired}</div>}
            {errorRegister && <div>{errorRegister}</div>}
          </div>
      </form>
    </div>
  );
};

export default StudentCreateProfile;

